import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'js-cookie'
// import axios from 'axios';

const base_url = 'https://www.keswin.app';
// const base_url = 'http://0.0.0.0:8000';

// UWk=Ssa2eqah=3Np

function Login() {

    const [isUser, setIsUser] = useState(false);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    function postLogin(e) {
        // e.preventDefault();
        console.log('form', username, password);
        if (username && password) {
            axios({
                method: 'post',
                baseURL: base_url,
                url: '/api-token-auth/',
                data: {username: username, password: password},
                })
                .then(response => {
                    Cookies.set('ud_token', response['data'].token);
                    axios({
                        method: 'get',
                        baseURL: base_url,
                        url: '/auth/users/me/',
                        headers: {
                            Authorization: 'Token ' + Cookies.get('ud_token')
                        }
                        })
                        .then(response => {
                            if (Cookies.get('ud_token')) {
                                setIsUser(true);
                            } else {
                                setIsUser(false);
                            }
                            console.log('success', response);
                        });
                        
                })
                .catch(function (error) {
                    console.log('error', error);
                    if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    console.log(error.request);
                    } else {
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
            });
        }
		
    }
    
    function handleUsername(e) {
        setUsername(e.target.value);
    }

    function handlePassword(e) {
        console.log('password', e.target.value);
		setPassword(e.target.value);
    }

    useEffect(() => {  
        console.log(password);
    }, [password]);


  return (
    <div> 
        <section id="loginForm">  
            <form 
            onSubmit={postLogin()}
            >
            <header className="normal">
                <div className="headerRow">
                    <div id="headerText">
                        <h1>Keswin Family</h1>
                        <h2>Photo Wall</h2>
                    </div>
                </div>
            </header>
            
                <div className="formField">
                    <input type="text" name="username" required onChange={(e) => handleUsername(e)} placeholder="username" />
                </div>
                <div className="formField">
                    <input type="password" name="password" required onChange={(e) => handlePassword(e)} placeholder="password" />
                </div>
                <div className="formField">
                    <input type="submit" className="btn" value="Sign In >" id="loginSubmit" />
                </div>
            </form>
        </section> 
    
    </div>
  )

}
export default Login;