import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import "../static/css/main.css";

import Login from './app.login';
import Player from './app.player';

function Display() {
  
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {  
    setIsUser(true);
  }, []);

  return (
    <Player />
  )

}
export default Display;