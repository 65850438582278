import React, { useState, useEffect} from "react";
import axios from 'axios';
import Cookies from 'js-cookie';


import Loader from "../static/svg/loaderImage.svg"
import userEvent from "@testing-library/user-event";

import SwiperCore, { Autoplay, EffectFade, EffectFlip } from 'swiper';
import { Swiper, SwiperSlide } from "swiper";

import 'swiper/swiper.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/effect-fade/effect-fade.min.css'
import 'swiper/modules/effect-flip/effect-flip.min.css'

const base_url = 'https://www.keswin.app';
// const base_url = 'http://0.0.0.0:8000';
SwiperCore.use([Autoplay, EffectFlip, EffectFade])

function App() {
    const frames = [0, 1, 2, 3, 4];
    const [swiperInit, setSwiperInit] = useState(false);
    const [medias, setMedias] = useState(null);
    const [modified, setModified] = useState(null);
    const [displayModified, setDisplayModified] = useState(null);
    const [transitionEffect, setTransitionEffect] = useState(null);
    const [animInit, setAnimInit] = useState(false);
    const [delay, setDelay] = useState(null);
    const [paused, setPaused] = useState(null);
    const [direction, setDirection] = useState('horizontal');
    const [timeouts, setTimeouts] = useState([]);
    const [pingDateTime, setPingDateTime] = useState(null);

    function pingDisplay() {
      var ping_datetime = new Date();
      axios({
        method: 'post',
        baseURL: base_url,
        url: '/api/ping/1/',
        data: {'ping_datetime': ping_datetime}
        }).then((response) => {
          setPingDateTime(ping_datetime);
        });
    }
   

    function fetchPlaylists() {
      try {
          const get_url = base_url + '/api/display-media/1/?format=json';
          axios({
              method: 'get',
              url: get_url,
              // headers: {
              //   Authorization: 'Token ' + Cookies.get('ud_token')
              // },
          })
          .then(response => {
              const displayJSON = response.data;
              var allMedia = displayJSON.medias;
              var allMediaLength = allMedia.length;
              var chunkLength = Math.ceil(allMediaLength/frames.length);

              console.log(allMedia, allMediaLength, chunkLength)

              const mediaArray = [];

              function chunk(array, size) {
                if (!array) return [];
                const firstChunk = array.slice(0, size); // create the first chunk of the given array
                if (!firstChunk.length) {
                  return array; // this is the base case to terminal the recursive
                }
                return [firstChunk].concat(chunk(array.slice(size, array.length), size)); 
              }

              // console.log('length', allMediaLength, frames.length, Math.ceil(allMediaLength/frames.length));

              var chunked = chunk(allMedia, chunkLength);
              if (chunked) {
                setMedias(chunked);
              }
              console.log('chunked', chunked)
              setModified(displayJSON['modified']);
              setDisplayModified(displayJSON['display_modified']);
              
              // console.log("MEDIA", mediaArray, allMedia, allMedia.length, chunked[0], allMediaLength/frames.length);
              const transition = displayJSON['transition'];
              const delay = displayJSON['timing']['seconds']*1000;
              
              setDelay(delay);
              const paused = displayJSON['paused'];
              console.log('delay', delay, 'paused', paused);
              
              if (transition === 'fade') {
                var transitionVal = 'fade';
                var directionVal = 'horizontal';
              } else if (transition === 'across') {
                var transitionVal = 'slide';
                var directionVal = 'horizontal';
              } else if (transition === 'up') {
                var transitionVal = 'slide';
                var directionVal = 'vertical';
              } else if (transition === 'flip') {
                var transitionVal = 'flip';
                var directionVal = 'vertical';
              } else {
                
              }

              setDirection(directionVal);
              setTransitionEffect(transitionVal);
              // setHasEffect(true);
              setPaused(paused);

              var swiperArray = [];
              var swipers = document.getElementsByClassName('swiper-container');
              var arrayLength = swipers.length;

              // function restartSwipers() {
              //   // counter-=counter;
              //   for (var i = 0; i < arrayLength; i++) {
              //     swipers[i].swiper.autoplay.start();
              //   }
              // }
    
              for (var i = 0; i < arrayLength; i++) {
                if (i === (arrayLength - 1)) {
                  var swiper = new Swiper('#swiper_' + i, {
                    loop: true,
                    slidesPerView: '1',
                    effect: transitionVal,
                    direction: directionVal,
                    speed: 750,
                    centeredSlides: true,
                    loop: false,
                    slidesPerView: 1,
                    autoplay: false,
                    on: {
                      init: function () {
                        
                          setSwiperInit(true);
                          pingDisplay();
        
                      },
                    }
                  });
                  swiperArray.push(swiper);

                } else {
                  var swiper = new Swiper('#swiper_' + i, {
                    loop: true,
                    slidesPerView: '1',
                    effect: transitionVal,
                    direction: directionVal,
                    speed: 750,
                    centeredSlides: true,
                    loop: false,
                    slidesPerView: 1,
                    autoplay: false
                  });
                  swiperArray.push(swiper);


                }
               
              }

              
          });
      } catch (error) {
          console.error(error);
          setPingDateTime('ERROR');
      }
    };
    
  useEffect(() => {        
    fetchPlaylists();
  }, []);

  useEffect(() => {        
    if (swiperInit) {
      console.log('swiperInit true')
    }
  }, [swiperInit]);

  function loadImage(e, image) {
    e.target.src = image;
}


  useEffect(() => {
    if (swiperInit) {

      var timeoutArray = [];

      var swiperElems = document.getElementsByClassName('swiper-container');
      // var swiperElems = swiper;
      var numSwipers = swiperElems.length;

      function runSlides(numSwipers, swiperIndex, delayVal){
        pingDisplay();
        var delay = delayVal;
        var currIndex = swiperElems[swiperIndex].swiper.activeIndex;
        var slidesLength = parseInt(swiperElems[swiperIndex].dataset.length);
        
        if (currIndex === (slidesLength - 1)) {
          var newSlideIndex = 0;
        } else {
          var newSlideIndex = currIndex + 1;
        }
       
        if (swiperIndex !== (numSwipers - 1)) {
          var newSwiperIndex = swiperIndex + 1;
        } else {
          var newSwiperIndex = 0;
        }

        setAnimInit(true);

        const swiperTimeout = setTimeout(() => {
          // console.log('runSlides', {currIndex: currIndex, slidesLength: slidesLength, newSlideIndex: newSlideIndex, newSwiperIndex: newSwiperIndex});
          swiperElems[swiperIndex].swiper.slideTo(newSlideIndex);

          if (paused) {
          } else {
            runSlides(numSwipers, newSwiperIndex, delay);
          }
        }, delayVal);
        setTimeouts(swiperTimeout);
        return () => clearTimeout(swiperTimeout);

      }

      if (paused) {
      } else {
        runSlides(numSwipers, 0, delay);
      }
      
    }


   
  }, [swiperInit, medias, paused, transitionEffect, delay])


  useEffect(() => {
    console.log(medias);
  }, [medias])

  useEffect(() => {
    if ((pingDateTime !== 'ERROR') && (pingDateTime !== null)) {
      console.log('ping', pingDateTime);
    } else {
      if (pingDateTime === 'ERROR') {
        window.location.reload(false);
      }
    }
  }, [pingDateTime])

  useEffect(() => {
    function checkDisplaySettings() {
      const get_url = base_url + '/api/display-media/1/?format=json';
      axios({
          method: 'get',
          url: get_url,
          // headers: {
          //   Authorization: 'Token ' + Cookies.get('ud_token')
          // },
      })
      .then(response => {
          const displayJSON = response.data;
          if ((displayModified)&&(modified)) {

            
            if (displayJSON['modified'] > modified) {
              setModified(displayJSON['modified']);
              setPaused(displayJSON['paused']);
              //  console.log('modified: true', displayJSON['modified'], modified);

               
            } else {
              if (displayJSON['display_modified'] > displayModified) {
                setDisplayModified(displayJSON['display_modified']);
                setPaused(displayJSON['paused']);
                // console.log('checkDisplaySettings: true', displayJSON['display_modified'], displayModified, displayJSON['modified'], modified);
              } else {
                setPaused(displayJSON['paused']);
                // console.log('checkDisplaySettings: false', displayJSON['paused'], displayModified, displayJSON['modified'], modified);
              }
 
            }

          }
      });
    }

    setInterval(() => {
      checkDisplaySettings();
    }, 1000 * 5);

  }, [animInit])

  useEffect(() => {
    if (animInit) {
      if (paused) {
        console.log('paused changed: true', paused);
        clearTimeout(timeouts);
        setSwiperInit(false);

      } else {
        console.log('paused changed: false', paused);
        setSwiperInit(true);
      }
    }
  }, [paused, modified])


  useEffect(() => {
    if (animInit) {
      if (modified) {
        window.location.reload(false);
      } else {
      }
    }
  }, [modified])
  


  return (
    <section className="mainPage" id="playerPage">
        {frames && frames.length > 0 && frames.map((frame, i) =>
          <div className="carousel" key={'frame_' + i}>
            {(medias && medias.length > 0) ? (
              
              <div className="swiper-container" data-length={medias[i].length} id={"swiper_" + i}>
                <div className="swiper-wrapper">
                  {medias[i].map((item, j) =>
                    <div className="swiper-slide" key={'image_' + j}>
                      <img src={Loader} onLoad={(e) => loadImage(e, item.get_image)} className={"slideImage" + ((item.is_portrait)?(' portrait'):(''))} alt="" />
                    </div>
                  )}
                </div>
              </div>
            ):null}
          </div>
        )}
    </section>
  )

}
export default App;

